.cart {
  background-color: #fff;
  box-shadow: 3px 5px 10px #aaa;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.cavalo {
  position: relative;
}

.trash-cart {
  width: 20px;
  height: 30px;
}

strong,
h1,
h2,
h3,
h4 {
  text-transform: capitalize;
}
.total {
  border: none;
  border-left: 1px solid #ddd;
}
.qtd button {
  background-color: #04c2af;
  height: 40px;
}
.qtd button:last-child {
  border-radius: 8px 0 0 8px;
}
.qtd button:first-child {
  border-radius: 8px 0 0 8px;
}

.qtd input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 40px;
  width: 100px;
  text-align: center;
  padding: 10px;
  -webkit-appearance: none;
}
