.screen {
  width: 60%;
  box-shadow: 3px 3px 8px #777;
  margin: 30px auto;
  border-radius: 10px;
  padding: 50px 25px;
  text-align: center;
}

.screen h5 {
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .screen {
    width: 100% !important;
    box-shadow: none !important;
    margin: 30px auto !important;
    border-radius: 10px !important;
    padding: 50px 25px !important;
    text-align: center !important;
  }
}

@media (max-width: 576px) {
  h4 {
    font-size: 18px !important;
  }

  h5 {
    font-size: 15px !important;
    width: 90% !important;
  }
}
