.filtro-conteiner{
  min-height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.filtro {
  background : rgba(0, 0, 0, 0.3);;
  color:#fff;
  margin: auto;
  min-height:400px;
  width:100%;
}
.filtro form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtro form span {
  width: 20%;
  white-space: nowrap;
}
.filtro form span div{
  width: 100%;
}

.filtro select, .filtro form span div input {
  height: 35px;
  border: 2px solid #cccccc;
  border-radius: 20px;
  width: 100%;
  padding: 0 15px;
}
.filtro select::-ms-expand {
  padding-right: 10px;
}

.filtro form button[type="button"] {
  height: 35px;
  border: none;
  background: #04c2af;
  align-self: flex-end;
  font-size: 15px;
  padding: 0 60px;
  border-radius: 70px;
  color: #fff;
}



@media (max-width: 768px) {
  .filtro form button[type="submit"] {
    width: 100%;
  }
}
