@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Rubik&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

html,
body,
#root {
  min-height: 100vh;
  background-color: #fff;
}

select,
a,
button,
input,
textarea {
  outline: 0;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

button,
a {
  cursor: pointer;
}
*:disabled {
  cursor: no-drop;
}

button:hover {
  opacity: 0.6;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.text-nowrap {
  white-space: nowrap;
}

.modal-order,
.modal-order .modal-dialog,
.modal-order .modal-content {
  min-width: 70vw;
}
