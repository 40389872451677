.grid-search-res {
  display: grid;
  grid-template-columns: 1fr 3fr;
  max-width: 94vw;
  margin: 0;
  padding: 30px;
  grid-gap: 30px;
}

@media (max-width: 850px) {
  .grid-search-res {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    max-width: 100vw;
    padding: 30px;
    grid-gap: 30px;
  }
}
@media (max-width: 650px) {
  .grid-search-res {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100vw;
    padding: 30px;
    grid-gap: 30px;
  }
}
