.modal-adicional .d-flex button.btn {
  background: #04c2af;
  border-radius: 0 20px 20px 0;
  height: 44px;
}

.btn-last {
  border-radius: 0 8px 8px 0;
  background: #04c2af;
}

.modal-adicionals div.d-flex input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 40px;
  width: 100px;
  text-align: center;
  padding: 10px;
  -webkit-appearance: none;
}

.modal-adicional .form input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 100%;
  width: 45%;
  text-align: center;
  padding: 10px;
}

.modal-adicional .form button {
  width: 55%;
  white-space: nowrap;
  color: #fff;
}

.modal-adicional .form .input {
  border: 2px solid #ddd;
  border-radius: 20px 0 0 20px;
  height: 44px;
}

.modal-adicional .header {
  background-color: #e6e6e6;
  padding: 5px 10px 0;
  cursor: pointer;
}
.modal-adicional .item {
  border: 1px solid #e6e6e6;
  padding: 5px 10px;
}
.modal-adicional .item + label {
  border-top: none;
}
.modal-adicional .itens {
  /* overflow: auto; */
  transition: all 0.5s;
}
.modal-adicional .rotatenone {
  transform: rotateX(180deg);
}

.modal-adicional select {
  width: 100%;
  font-size: 20px;
}

.modal-adicional .remove-coupon {
  width: 30px;
}

.modal-informacao div div div select {
  overflow: hidden;
  background: url("../../assets/arrowSelect.svg") no-repeat right;
  background-position: 95%;
  background-size: 17px;
  appearance: none;
}

.exit {
  width: 55px;
}
