div.infoHeader {
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
  background-color: #2d2d2d;
}

div.infoHeader div {
  display: flex;
  align-items: center;
  align-self: center;
}

div.infoHeader button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0 15px;
  color: #fff;
}

div.infoHeader button img {
  margin: 0 10px;
}

div.infoHeader div a {
  margin-right: 25px;
  color: #fff;
}

div.infoHeader div a img {
  margin: 0 10px;
}

.button {
  background: none;
  border: none;
}

.button img {
  width: 25px;
}

.itens {
  height: 35px;
  white-space: nowrap;
}

.navbar a{
  color:#fff;
}