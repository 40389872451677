.footer {
  /* margin-top: 50px; */
  background-color: #2d2d2d;
  color: #fff;
  padding: 20px;
}

.footer div form input {
  padding: 4px;
  padding-left: 15px;
  border-radius: 30px 0 0 30px;
  border: 2px solid #aaa;
  width: 70%;
}
.footer div form button[type="submit"] {
  border-radius: 0 30px 30px 0;
  padding: 6px;
  border: none;
  background: #04c2af;
}

.footer div form img {
  margin: auto;
}

.footer ul li {
  display: flex;
  align-items: flex-start;
  margin: 10px;
}

.footer div a + a {
  margin-left: 20px;
}

.footer ul img {
  margin: 0 15px;
}

.footer ul a {
  color: #fff;
}

.subfooter {
  background-color: #2d2d2d;
  color: #fff;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subfooter p {
  margin: 0;
  padding: 0;
}

@media (max-width: 950px) {
  .footer ul {
    width: 100%;
    flex: 1;
    margin: 0;
  }
  .footer ul,
  .footer > div {
    align-self: flex-start;
    margin: 15px;
  }
  .footer :nth-child(2) {
    order: 1;
  }

  .footer :nth-child(4) {
    order: 3;
    padding: 0 0 20px 0;
  }

  .footer > div:first-child {
    margin: 25px 0;
  }
}
