.res-cavalo {
  display: flex;
  grid-template-columns: 1.5fr 1fr;
  margin: 50px auto;
  padding: 30px;
  background-color: #fff;
}

.res-cavalo .imagens {
  display: flex;
  flex-direction: row;
}

/* .res-cavalo .imagens > img {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
  object-fit: cover;
} */
.res-cavalo .imagens span img {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.res-cavalo .info div label select {
  border: 2px solid #aaa;
  border-radius: 10px;
}

.res-cavalo .d-flex button.btn {
  background: #04c2af;
  border-radius: 8px 0 0 8px;
  height: 40px;
}

.res-cavalo .d-flex button.btn:last-child {
  border-radius: 0 8px 8px 0;
}

.res-cavalo div.d-flex input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 40px;
  width: 100px;
  text-align: center;
  padding: 10px;
  -webkit-appearance: none;
}
.res-cavalo div.buttons {
  display: flex;
}
.res-cavalo div.buttons button.btn-outline-info.btn,
.res-cavalo div.buttons button.btn-info.btn {
  font-size: 20px;
  padding: 10px 30px;
  margin: 20px;
  width: 40%;
  border-radius: 10px;
  font-display: flex;
}

.res-cavalo div.buttons button.btn-outline-info.btn:hover,
.res-cavalo div.buttons button.btn-info.btn:hover {
  opacity: 1;
}
