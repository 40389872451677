.signin-conteiner {
  background: #fff;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 5px;
}

.signin-conteiner .divider {
  width: 3px;
  background-color: #d4d4d4;
}

@media (max-width: 768px) {
  .signin-conteiner .divider {
    width: 100%;
    background-color: #d4d4d4;
    height: 3px;
    margin: 20px 0;
  }
}
