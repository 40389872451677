.finalityTasks {
  margin-top: 30px;
}
.finalityTasks p {
  text-align: center;
  white-space: nowrap;
}

.finalityTasks span div.circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 5px solid #000;
}
.finalityTasks span div.circle.finaly {
  background-color: #04c2af;
  border: 5px solid #01d4bf;
}
.finalityTasks .divider {
  height: 4px;
  width: 100%;
  margin: 0 20px;
  background: #000;
}

.finalityTasks .current {
  background: linear-gradient(to left, #000 50%, #01d4bf 50%);
}
@media (max-width: 700px) {
  .finalityTasks p {
    white-space: normal;
  }
}
