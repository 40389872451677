.screen{
  width:60%;
  box-shadow: 3px 3px 8px #777;
  margin:30px auto;
  border-radius:10px;
  padding:50px 25px;
  text-align: center;
}

.screen h5{
  width:50%;
  margin:0 auto;
}