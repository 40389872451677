.pagination {
  display: flex;
}
@media print {
  .deleteForPint,
  .pagination {
    display: none;
  }

  .page {
    width: 100vw;
    height: 100vh;
  }
  .bg-light {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    background-color: #fff;
  }
  .bg-white {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
  }

  .table-responsive {
    width: 100vh;
    height: 100vh;
  }

  table {
    width: 100vh;
    margin: 0 auto;
  }

  table td {
    border: 2px solid #aaa;
  }
}
