@media (max-width: 575px) {
  .aside {
    position: absolute;
    right: 0;
    max-height: 90%;
    transition: left 1s;
  }
}

.aside {
  overflow: auto;
  position: relative;
  left: 0;
  bottom: 0;
}
