.chekout .cavalos .cavalo {
  background: #fff;
  padding: 20px 10px;
  box-shadow: 3px 4px 6px #aaa;
  border-radius: 10px;
}

.chekout .sumary {
  box-shadow: 4px 4px 6px #aaa;
  background: #fff;
  border-radius: 10px;
}

.chekout .shipInfo h4 {
  cursor: pointer;
}
