.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  padding: 20px;
}

.grid img {
  width: 70%;
  height: 200px;
  margin: 20px auto;
}

@media (max-width: 800px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 480px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
  }
}
