@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Rubik&display=swap);
.home {
  background-image: url(/static/media/fastsemenbg.a8dcf1c7.jpeg);
  background-size: cover;
  background-position: center;
  position: relative;
}

div.infoHeader {
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
  background-color: #2d2d2d;
}

div.infoHeader div {
  display: flex;
  align-items: center;
  align-self: center;
}

div.infoHeader button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0 15px;
  color: #fff;
}

div.infoHeader button img {
  margin: 0 10px;
}

div.infoHeader div a {
  margin-right: 25px;
  color: #fff;
}

div.infoHeader div a img {
  margin: 0 10px;
}

.button {
  background: none;
  border: none;
}

.button img {
  width: 25px;
}

.itens {
  height: 35px;
  white-space: nowrap;
}

.navbar a{
  color:#fff;
}
.filtro-conteiner{
  min-height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.filtro {
  background : rgba(0, 0, 0, 0.3);;
  color:#fff;
  margin: auto;
  min-height:400px;
  width:100%;
}
.filtro form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtro form span {
  width: 20%;
  white-space: nowrap;
}
.filtro form span div{
  width: 100%;
}

.filtro select, .filtro form span div input {
  height: 35px;
  border: 2px solid #cccccc;
  border-radius: 20px;
  width: 100%;
  padding: 0 15px;
}
.filtro select::-ms-expand {
  padding-right: 10px;
}

.filtro form button[type="button"] {
  height: 35px;
  border: none;
  background: #04c2af;
  align-self: flex-end;
  font-size: 15px;
  padding: 0 60px;
  border-radius: 70px;
  color: #fff;
}



@media (max-width: 768px) {
  .filtro form button[type="submit"] {
    width: 100%;
  }
}

.res-cavalo {
  display: flex;
  grid-template-columns: 1.5fr 1fr;
  margin: 50px auto;
  padding: 30px;
  background-color: #fff;
}

.res-cavalo .imagens {
  display: flex;
  flex-direction: row;
}

/* .res-cavalo .imagens > img {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
  object-fit: cover;
} */
.res-cavalo .imagens span img {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.res-cavalo .info div label select {
  border: 2px solid #aaa;
  border-radius: 10px;
}

.res-cavalo .d-flex button.btn {
  background: #04c2af;
  border-radius: 8px 0 0 8px;
  height: 40px;
}

.res-cavalo .d-flex button.btn:last-child {
  border-radius: 0 8px 8px 0;
}

.res-cavalo div.d-flex input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 40px;
  width: 100px;
  text-align: center;
  padding: 10px;
  -webkit-appearance: none;
}
.res-cavalo div.buttons {
  display: flex;
}
.res-cavalo div.buttons button.btn-outline-info.btn,
.res-cavalo div.buttons button.btn-info.btn {
  font-size: 20px;
  padding: 10px 30px;
  margin: 20px;
  width: 40%;
  border-radius: 10px;
  font-display: flex;
}

.res-cavalo div.buttons button.btn-outline-info.btn:hover,
.res-cavalo div.buttons button.btn-info.btn:hover {
  opacity: 1;
}

.modal-adicional .d-flex button.btn {
  background: #04c2af;
  border-radius: 0 20px 20px 0;
  height: 44px;
}

.btn-last {
  border-radius: 0 8px 8px 0;
  background: #04c2af;
}

.modal-adicionals div.d-flex input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 40px;
  width: 100px;
  text-align: center;
  padding: 10px;
  -webkit-appearance: none;
}

.modal-adicional .form input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 100%;
  width: 45%;
  text-align: center;
  padding: 10px;
}

.modal-adicional .form button {
  width: 55%;
  white-space: nowrap;
  color: #fff;
}

.modal-adicional .form .input {
  border: 2px solid #ddd;
  border-radius: 20px 0 0 20px;
  height: 44px;
}

.modal-adicional .header {
  background-color: #e6e6e6;
  padding: 5px 10px 0;
  cursor: pointer;
}
.modal-adicional .item {
  border: 1px solid #e6e6e6;
  padding: 5px 10px;
}
.modal-adicional .item + label {
  border-top: none;
}
.modal-adicional .itens {
  /* overflow: auto; */
  transition: all 0.5s;
}
.modal-adicional .rotatenone {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.modal-adicional select {
  width: 100%;
  font-size: 20px;
}

.modal-adicional .remove-coupon {
  width: 30px;
}

.modal-informacao div div div select {
  overflow: hidden;
  background: url(/static/media/arrowSelect.960531b3.svg) no-repeat right;
  background-position: 95%;
  background-size: 17px;
  -webkit-appearance: none;
          appearance: none;
}

.exit {
  width: 55px;
}

.footer {
  /* margin-top: 50px; */
  background-color: #2d2d2d;
  color: #fff;
  padding: 20px;
}

.footer div form input {
  padding: 4px;
  padding-left: 15px;
  border-radius: 30px 0 0 30px;
  border: 2px solid #aaa;
  width: 70%;
}
.footer div form button[type="submit"] {
  border-radius: 0 30px 30px 0;
  padding: 6px;
  border: none;
  background: #04c2af;
}

.footer div form img {
  margin: auto;
}

.footer ul li {
  display: flex;
  align-items: flex-start;
  margin: 10px;
}

.footer div a + a {
  margin-left: 20px;
}

.footer ul img {
  margin: 0 15px;
}

.footer ul a {
  color: #fff;
}

.subfooter {
  background-color: #2d2d2d;
  color: #fff;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subfooter p {
  margin: 0;
  padding: 0;
}

@media (max-width: 950px) {
  .footer ul {
    width: 100%;
    flex: 1 1;
    margin: 0;
  }
  .footer ul,
  .footer > div {
    align-self: flex-start;
    margin: 15px;
  }
  .footer :nth-child(2) {
    order: 1;
  }

  .footer :nth-child(4) {
    order: 3;
    padding: 0 0 20px 0;
  }

  .footer > div:first-child {
    margin: 25px 0;
  }
}



.contact {
  background: #fff;
  box-shadow: 0px 2px 6px #00000029;
  padding: 50px;
}

.contact form {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 0px auto;
}
.contact form h2 {
  margin-bottom: 30px;
}
.contact form label {
  display: flex;
  flex-direction: column;
}
.contact form label input,
.contact form label textarea {
  border: 2px solid #aaa;
  padding: 4px;
  border-radius: 6px;
}

.sigin .form {
  background: #ffffff;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 5px;
}

.sigin form input[type="chechbix"] {
  border: 1px solid #ced4da;
}

.signin-conteiner {
  background: #fff;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 5px;
}

.signin-conteiner .divider {
  width: 3px;
  background-color: #d4d4d4;
}

@media (max-width: 768px) {
  .signin-conteiner .divider {
    width: 100%;
    background-color: #d4d4d4;
    height: 3px;
    margin: 20px 0;
  }
}

.cart {
  background-color: #fff;
  box-shadow: 3px 5px 10px #aaa;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.cavalo {
  position: relative;
}

.trash-cart {
  width: 20px;
  height: 30px;
}

strong,
h1,
h2,
h3,
h4 {
  text-transform: capitalize;
}
.total {
  border: none;
  border-left: 1px solid #ddd;
}
.qtd button {
  background-color: #04c2af;
  height: 40px;
}
.qtd button:last-child {
  border-radius: 8px 0 0 8px;
}
.qtd button:first-child {
  border-radius: 8px 0 0 8px;
}

.qtd input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 40px;
  width: 100px;
  text-align: center;
  padding: 10px;
  -webkit-appearance: none;
}

.chekout .cavalos .cavalo {
  background: #fff;
  padding: 20px 10px;
  box-shadow: 3px 4px 6px #aaa;
  border-radius: 10px;
}

.chekout .sumary {
  box-shadow: 4px 4px 6px #aaa;
  background: #fff;
  border-radius: 10px;
}

.chekout .shipInfo h4 {
  cursor: pointer;
}

.finalityTasks {
  margin-top: 30px;
}
.finalityTasks p {
  text-align: center;
  white-space: nowrap;
}

.finalityTasks span div.circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 5px solid #000;
}
.finalityTasks span div.circle.finaly {
  background-color: #04c2af;
  border: 5px solid #01d4bf;
}
.finalityTasks .divider {
  height: 4px;
  width: 100%;
  margin: 0 20px;
  background: #000;
}

.finalityTasks .current {
  background: linear-gradient(to left, #000 50%, #01d4bf 50%);
}
@media (max-width: 700px) {
  .finalityTasks p {
    white-space: normal;
  }
}


.grid-search-res {
  display: grid;
  grid-template-columns: 1fr 3fr;
  max-width: 94vw;
  margin: 0;
  padding: 30px;
  grid-gap: 30px;
}

@media (max-width: 850px) {
  .grid-search-res {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    max-width: 100vw;
    padding: 30px;
    grid-gap: 30px;
  }
}
@media (max-width: 650px) {
  .grid-search-res {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100vw;
    padding: 30px;
    grid-gap: 30px;
  }
}

.screen {
  width: 60%;
  box-shadow: 3px 3px 8px #777;
  margin: 30px auto;
  border-radius: 10px;
  padding: 50px 25px;
  text-align: center;
}

.screen h5 {
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .screen {
    width: 100% !important;
    box-shadow: none !important;
    margin: 30px auto !important;
    border-radius: 10px !important;
    padding: 50px 25px !important;
    text-align: center !important;
  }
}

@media (max-width: 576px) {
  h4 {
    font-size: 18px !important;
  }

  h5 {
    font-size: 15px !important;
    width: 90% !important;
  }
}

.screen{
  width:60%;
  box-shadow: 3px 3px 8px #777;
  margin:30px auto;
  border-radius:10px;
  padding:50px 25px;
  text-align: center;
}

.screen h5{
  width:50%;
  margin:0 auto;
}
.screen{
  width:60%;
  box-shadow: 3px 3px 8px #777;
  margin:30px auto;
  border-radius:10px;
  padding:50px 25px;
  text-align: center;
}

.screen h5{
  width:50%;
  margin:0 auto;
}
.cart {
  background-color: #fff;
  box-shadow: 3px 5px 10px #aaa;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.cavalo{
  position:relative;
}

.trash{
  width:20px;
  position:absolute;
  right:10px;
  bottom:10px;
  cursor:pointer;
}

strong,
h1,
h2,
h3,
h4 {
  text-transform: capitalize;
}
.total{
  border:none;
  border-left:1px solid #ddd;
}
.qtd button {
  background-color: #04c2af;
  height: 40px;
}
.qtd button:last-child {
  border-radius: 8px 0 0 8px;
}
.qtd button:first-child {
  border-radius: 8px 0 0 8px;
}

.qtd input {
  background: #fff;
  border: none;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  height: 40px;
  width: 100px;
  text-align: center;
  padding: 10px;
  -webkit-appearance: none;
}

.container-forgot {
  background: #f9f9f9;
  box-shadow: 0px 3px 6px #999;
  border-radius: 5px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  padding: 20px;
}

.grid img {
  width: 70%;
  height: 200px;
  margin: 20px auto;
}

@media (max-width: 800px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 480px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 575px) {
  .aside {
    position: absolute;
    right: 0;
    max-height: 90%;
    transition: left 1s;
  }
}

.aside {
  overflow: auto;
  position: relative;
  left: 0;
  bottom: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  padding: 20px;
}

.grid img {
  width: 70%;
  height: 200px;
  margin: 20px auto;
}

@media (max-width: 800px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 480px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.pagination {
  display: flex;
}
@media print {
  .deleteForPint,
  .pagination {
    display: none;
  }

  .page {
    width: 100vw;
    height: 100vh;
  }
  .bg-light {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    background-color: #fff;
  }
  .bg-white {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
  }

  .table-responsive {
    width: 100vh;
    height: 100vh;
  }

  table {
    width: 100vh;
    margin: 0 auto;
  }

  table td {
    border: 2px solid #aaa;
  }
}

.conteiner-modal img {
  height: 100px;
  width: 150px;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

html,
body,
#root {
  min-height: 100vh;
  background-color: #fff;
}

select,
a,
button,
input,
textarea {
  outline: 0;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

button,
a {
  cursor: pointer;
}
*:disabled {
  cursor: no-drop;
}

button:hover {
  opacity: 0.6;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -webkit-appearance: textfield;
          appearance: textfield;
}

.text-nowrap {
  white-space: nowrap;
}

.modal-order,
.modal-order .modal-dialog,
.modal-order .modal-content {
  min-width: 70vw;
}

