.contact {
  background: #fff;
  box-shadow: 0px 2px 6px #00000029;
  padding: 50px;
}

.contact form {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 0px auto;
}
.contact form h2 {
  margin-bottom: 30px;
}
.contact form label {
  display: flex;
  flex-direction: column;
}
.contact form label input,
.contact form label textarea {
  border: 2px solid #aaa;
  padding: 4px;
  border-radius: 6px;
}
